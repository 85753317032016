import { Link } from 'gatsby';
import * as React from 'react';

import * as styles from './navigation.module.scss';
import { Squash as Hamburger } from 'hamburger-react';

const Navigation = () => {
  let [showMenu, setShowMenu] = React.useState(false);

  const clickHandler = () => {
    setShowMenu(false);
  };

  return (
    <>
      <div className={styles.menuBtn}>
        <Hamburger toggled={showMenu} toggle={setShowMenu}></Hamburger>
      </div>

      <div className={styles.menu + (showMenu ? ' ' + styles.show : '')}>
        <nav>
          <span className={styles.menuTitle}>Menu</span>

          <div className={styles.menuScroll}>
            <Link onClick={clickHandler} to={'/'}>
              Home
            </Link>
            <Link onClick={clickHandler} to={'/test'}>
              Test
            </Link>
          </div>
        </nav>
      </div>
    </>
  );
};

/*const query = graphql`

`*/

export default Navigation;

import * as React from 'react';
import propTypes from 'prop-types';
import '../global.scss';
import * as styles from './page-default.module.scss';

import { MDXProvider } from '@mdx-js/react';
import { Link } from 'gatsby';
import Seo from '../components/seo';
import Navigation from '../components/navigation';

const PreHeader = ({ children }) => {
  return <span className={styles.preHeader}>{children}</span>;
};

const shortcodes = { Link, Seo, PreHeader };

const PageDefaultLayout = ({ children }) => {
  return (
    <main className={styles.main}>
      <Seo></Seo>
      <Navigation key={'global-nav'} />
      <div className={styles.content}>
        <MDXProvider components={shortcodes}>{children}</MDXProvider>
      </div>

      <footer>
        <Link to={'/imprint'}>Imprint</Link> &middot;{' '}
        <Link to={'/privacy'}>Data Protection</Link> &middot; Webdesign by{' '}
        <Link to={'https://kevink.dev'} rel="noreferrer" target="_blank">
          Kevin Kandlbinder
        </Link>
      </footer>
    </main>
  );
};

PageDefaultLayout.propTypes = {
  children: propTypes.any,
};

export default PageDefaultLayout;
